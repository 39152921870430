import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { UnstyledLink } from '../atoms/link'
import { Row, Column } from '../atoms/grid'
import VideoPlayer from '../atoms/homepage-video-player'
import { H4 } from '../atoms/heading'
import { Hero } from '../molecules/hero'
import { FooterHero } from '../molecules/footer-hero'
import AccoladesSection from '../molecules/accolades-section'
import FeaturedSection from '../molecules/featured-section'
import { FeaturedPressSection } from '../molecules/featured-press-section'
import TestimonialCarousel from '../molecules/testimonial-carousel'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'
import { IContentfulCompany } from '../interfaces'
import { IContentfulKeyValueProp } from '../interfaces/contentful-key-value-prop'

const CompaniesContainer = styled.div`
  padding: 3rem 5%;

  ${Mq.md} {
    padding: 2rem 3%;
    margin: 0 5%;
  }

  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const CompaniesColumn = styled(Column)`
  text-align: center;

  svg {
    width: 100px;
    height: 30px;

    image {
      width: 100px;
      height: 30px;
    }
  }

  ${Mq.md} {
    svg {
      width: 110px;
      height: 50px;

      image {
        width: 110px;
        height: 50px;
      }
    }
  }
  ${Mq.lg} {
    svg {
      width: 120px;
      height: 60px;

      image {
        width: 120px;
        height: 60px;
      }
    }
  }
`

const KeyValuePropsContainer = styled.div`
  padding: 2rem 5% 2rem;

  ${Mq.md} {
    padding: 2rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const KeyValueProp = styled(Column)`
  svg {
    width: 45px;
    height: 45px;

    image {
      width: 45px;
      height: 45px;
    }
  }

  ${Mq.md} {
    svg {
      width: 65px;
      height: 65px;

      image {
        width: 65px;
        height: 65px;
      }
    }
  }
`

const KeyValueTitle = styled(H4)`
  display: inline-block;
  margin: 1rem 0 0.5rem 1.5rem;

  ${Mq.md} {
    display: block;
    margin: 1rem 0;
  }
`

const IndexPage = () => {
  const { contentfulHomePage } = useStaticQuery(graphql`
    query HomeQuery {
      contentfulHomePage {
        pageTitle
        pageDescription
        videoUrl
        videoClosedCaptionsUrl
        videoTitle
        videoSubtitle
        companies {
          logo {
            file {
              url
            }
          }
          link {
            ...Link
          }
        }
        accolades {
          logo {
            gatsbyImageData(width: 450, layout: CONSTRAINED)
          }
          description
          title
        }
        featuredSections {
          title
          subtitle
          squareImage {
            gatsbyImageData(width: 432, layout: CONSTRAINED)
          }
          verticalImage {
            gatsbyImageData(width: 218, layout: CONSTRAINED)
          }
          horizontalImage {
            gatsbyImageData(width: 316, layout: CONSTRAINED)
          }
        }
        keyValueProps {
          title
          icon {
            file {
              url
            }
          }
        }
        testimonials {
          author
          authorTitle
          quote
        }
        pressItems {
          title
          publishDate
          publication
          logo {
            file {
              url
            }
          }
          blurb
          link {
            ...Link
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        description={contentfulHomePage.pageDescription}
        title={contentfulHomePage.pageTitle}
      />
      <Hero showWaves={true} />

      {process.env.GATSBY_HOME_VIDEO_SECTION !== 'false' ? (
        <VideoPlayer
          src={contentfulHomePage.videoUrl}
          closedCaptionsSrc={contentfulHomePage.videoClosedCaptionsUrl}
          title={contentfulHomePage.videoTitle}
          subtitle={contentfulHomePage.videoSubtitle}
        />
      ) : null}

      {process.env.GATSBY_COMPANY_SECTION !== 'false' ? (
        <CompaniesContainer>
          <Fade bottom distance={Theme.fadeDistance}>
            <Row mb={[3, 4]}>
              <H4>Trusted by the world's largest companies</H4>
            </Row>
          </Fade>

          <Fade bottom cascade distance={Theme.fadeDistance}>
            <Row flexWrap="wrap" flex="1" justifyContent="end">
              {contentfulHomePage.companies.map(
                (company: IContentfulCompany, index: number) => {
                  return (
                    <CompaniesColumn
                      key={index}
                      width={[1 / 2, 1 / 3, 1 / 4, 1 / 5]}
                      my={[3, 4]}
                      pr={[2, 2]}
                    >
                      <UnstyledLink
                        aria-label={company.link.title}
                        href={company.link.externalLink}
                      >
                        <img
                          src={company.logo.file.url}
                          alt={company.link.title}
                          width="120"
                          height="50"
                        />
                      </UnstyledLink>
                    </CompaniesColumn>
                  )
                },
              )}
            </Row>
          </Fade>
        </CompaniesContainer>
      ) : null}

      {process.env.GATSBY_FEATURED_SECTION !== 'false' ? (
        <FeaturedSection
          featuredSections={contentfulHomePage.featuredSections}
        />
      ) : null}

      <KeyValuePropsContainer>
        <Fade bottom cascade distance={Theme.fadeDistance}>
          <Row flexWrap="wrap" flex="1">
            {contentfulHomePage.keyValueProps.map(
              (prop: IContentfulKeyValueProp, index: number) => {
                return (
                  <KeyValueProp
                    key={index}
                    my={[3, 4]}
                    pr={[0, 30]}
                    width={[1, 1 / 2, 1 / 2, 1 / 3]}
                  >
                    <svg width="65" height="65" viewBox="0 0 65 65">
                      <image
                        xlinkHref={prop.icon.file.url}
                        href={prop.icon.file.url}
                        width="65"
                        height="65"
                      />
                    </svg>
                    <KeyValueTitle>{prop.title}</KeyValueTitle>
                  </KeyValueProp>
                )
              },
            )}
          </Row>
        </Fade>
      </KeyValuePropsContainer>

      {process.env.GATSBY_TESTIMONIAL_SECTION !== 'false' ? (
        <TestimonialCarousel testimonials={contentfulHomePage.testimonials} />
      ) : null}

      {process.env.GATSBY_PRESS_SECTION !== 'false' ? (
        <FeaturedPressSection items={contentfulHomePage.pressItems} />
      ) : null}

      {process.env.GATSBY_ACCOLADES_SECTION !== 'false' ? (
        <AccoladesSection items={contentfulHomePage.accolades} />
      ) : null}

      <FooterHero />
    </Layout>
  )
}

export default IndexPage
