import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { Row } from '../atoms/grid'
import { H2, H5 } from '../atoms/heading'
import { IContentfulAccolade } from '../interfaces/contentful-accolade'

const AccoladesSectionContainer = styled.div`
  padding: 3rem 5% 1rem;

  ${Mq.md} {
    padding: 7rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const AccoladeColumn = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  margin-right: 1.4rem;
  margin-bottom: 1.4rem;
  padding: 1.1rem;
  width: 42.5%;
  height: 24rem;

  ${Mq.md} {
    padding: 1.2rem;
    width: 30%;
  }

  ${Mq.lg} {
    padding: 1.4rem;
    width: 20%;
  }
`

const AccoladeImg = styled(GatsbyImage)`
  margin: 0 auto;
  max-height: 30rem;
  width: 90%;

  ${Mq.md} {
    max-height: 34rem;
    display: block;
  }
`

const Description = styled(H5)`
  width: 100%;
  margin: 2rem 0.5rem;
  font-size: 0.8125rem;

  ${Mq.sm} {
    font-size: 1rem;
  }

  ${Mq.lg} {
    display: block;
    margin: 2rem 0 1rem;
  }
`

const Title = styled(H2)`
  margin-bottom: 30px;
`

export const AccoladesSection = ({
  items,
}: {
  items: IContentfulAccolade[]
}) => (
  <AccoladesSectionContainer>
    <Fade bottom={true} distance={Theme.fadeDistance}>
      <Row>
        <Title>Awards & Certifications</Title>
      </Row>
    </Fade>

    <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
      <Row flexWrap="wrap" flex="1" justifyContent="start">
        {items.map((accolade: IContentfulAccolade, index: number) => {
          return (
            <AccoladeColumn key={index}>
              <AccoladeImg
                image={accolade.logo.gatsbyImageData}
                alt={accolade.title}
              />
              <Description>{accolade.description}</Description>
            </AccoladeColumn>
          )
        })}
      </Row>
    </Fade>
  </AccoladesSectionContainer>
)

export default AccoladesSection
