import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { Global, css } from '@emotion/react'
import { rgba } from 'polished'

import Theme, { Mq } from '../themes/default'

interface IProps {
  src: string
  closedCaptionsSrc: string
  title: string
  subtitle: string
}

const videoJsOptions = {
  autoplay: true,
  controls: true,
  disablePictureInPicture: true,
  fluid: true,
  loop: true,
  muted: true,
  nativeControlsForTouch: true,
  preload: 'auto',
  userActions: { hotkeys: true },
}

const closedCaptionOptions = {
  kind: 'captions',
  srclang: 'en',
  label: 'English',
  src: '',
}

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 5%;
  padding-left: 5%;

  ${Mq.md} {
    margin-top: -5%;
    padding-right: 0;
  }

  ${Mq.xxl} {
    padding-left: 15%;
  }
`

const Video = styled.div`
  width: 100%;
`

const videoStyles = css`
  .video-js {
    cursor: pointer;

    &:hover {
      filter: grayscale(0);

      .vjs-tech {
        filter: grayscale(0);
      }
    }

    .vjs-tech {
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: grayscale(0);
      }

      ${Mq.md} {
        filter: grayscale(100%);
      }
    }

    &.vjs-fullscreen {
      .vjs-big-play-button {
        display: none;
      }
    }

    .vjs-big-play-button {
      &--custom {
        display: block;
      }

      left: 1rem;
      bottom: 1rem;
      top: auto;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      background: ${Theme.colors.primary};
      box-shadow: 0px 0px 0px 8px ${rgba(Theme.colors.primary, 0.2)};
      border-color: ${Theme.colors.primary};

      &:active,
      &:hover,
      &:focus {
        border-color: ${Theme.colors.primary};
        box-shadow: 0px 0px 0px 12px ${rgba(Theme.colors.primary, 0.4)};
        background: ${Theme.colors.primary};
      }

      ${Mq.md} {
        left: 2rem;
        bottom: 6%;
      }

      .vjs-icon-placeholder {
        &:before {
          font-size: 2.5rem;
          top: 12%;
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      .vjs-big-play-button {
        border-color: ${Theme.colors.primary};
        box-shadow: 0px 0px 0px 12px ${rgba(Theme.colors.primary, 0.4)};
        background: ${Theme.colors.primary};
      }
    }

    .vjs-big-play-button__text {
      position: absolute;
      bottom: 1rem;
      left: 6.5rem;
      text-align: left;
      min-width: 30rem;
      line-height: 2rem;
      color: #fff;
      font-size: 1.6rem;

      ${Mq.md} {
        font-size: 1.8rem;
        left: 8rem;
        bottom: 6%;
      }
    }

    .vjs-big-play-button__title {
      color: #fff;
    }

    .vjs-big-play-button__subtitle {
      font-size: 1.0625rem;
    }
  }
`

const HomePageVideoPlayer = (props: IProps) => {
  const [isShowingOverlay, setIsShowingOverlay] = useState(false)
  const { src, closedCaptionsSrc, title, subtitle } = props

  const playerRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsShowingOverlay(true)

      const player = videojs(playerRef.current, videoJsOptions, () => {
        player.src({
          src,
          type: 'video/mp4',
        })
      })

      closedCaptionOptions.src = closedCaptionsSrc
      player.addRemoteTextTrack(closedCaptionOptions)
      player.controls(false)

      // Allow any click to fullscreen mode only once
      player.one('click', () => {
        if (!player.isFullscreen()) {
          setIsShowingOverlay(false)
          player.requestFullscreen()
          player.currentTime(0)
          player.muted() && player.muted(false)
          player.play()
          player.controls(true)

          if (typeof window !== 'undefined')
            window.analytics.track('Home Video Played', {})
        }
      })

      return () => player.dispose()
    }
  }, [])

  return (
    <>
      <Global styles={videoStyles} />
      <VideoContainer>
        <Video>
          <div data-vjs-player>
            <video ref={playerRef} className="video-js vjs-16-9" />
            {isShowingOverlay && (
              <>
                <button
                  aria-disabled="false"
                  className="vjs-big-play-button vjs-big-play-button--custom"
                  title="Play Video"
                  type="button"
                >
                  <span aria-hidden="true" className="vjs-icon-placeholder" />
                  <span className="vjs-control-text" aria-live="polite">
                    Play Video
                  </span>
                </button>
                <div className="vjs-big-play-button__text">
                  <h3 className="vjs-big-play-button__title">{title}</h3>
                  <div className="vjs-big-play-button__subtitle">
                    {subtitle}
                  </div>
                </div>
              </>
            )}
          </div>
        </Video>
      </VideoContainer>
    </>
  )
}

export default HomePageVideoPlayer
